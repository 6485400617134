import { Component, OnInit } from '@angular/core';
import { TarolandoService } from '../tarolando.service';

declare var $ :any;

@Component({
  selector: 'app-ficha-tube-hal',
  templateUrl: './ficha-tube-hal.component.html',
  styleUrls: ['./ficha-tube-hal.component.css']
})
export class FichaTubeHalComponent implements OnInit {

  ItensTaRolando:any;
  ItensTaRolandoDesktop:any;
  
  constructor(private _taRolandoService:TarolandoService) { }

  ngOnInit() {

    this.getItensAtividades();
    this.getItensAtividadesDesktop();
  }


  getItensAtividades()
  {
    this._taRolandoService.jsonTaRolandoTube().subscribe((data) => {
      this.ItensTaRolando = data;    },()=>{},()=>{

        setTimeout(()=>{
          $("#clients").owlCarousel({
            items: 2,
            autoPlay: true,
            loop: true,
            slideSpeed: 300,
            paginationSpeed: 800,
            autoplayTimeout: 100,
            autoplayHoverPause: true,
            startPosition: 5
          });         
        },200)       
      })
  }

  getItensAtividadesDesktop()
  {
    this._taRolandoService.jsonTaRolandoTubeDesktop().subscribe((data) => {
      this.ItensTaRolandoDesktop = data;    },()=>{},()=>{

        setTimeout(()=>{
          $("#clientss").owlCarousel({
            items: 1,
            autoPlay: false,
            loop: false,
            slideSpeed: 300,
            paginationSpeed: 800,
            autoplayTimeout: 100,
            autoplayHoverPause: false,
            startPosition: 5
          });         
        },200)       
      })
  } 

  carouselCards()
  {
    $(".slider").owlCarousel({
      loop: false,
      autoplay: false,
      autoplayTimeout: 2000, //2000ms = 2s;
      autoplayHoverPause: false,
    });
  }
}
