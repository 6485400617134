import { Component } from '@angular/core';
import { HomeService } from './home.service';
import { TarolandoService } from './tarolando.service';

declare var $:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  Titulos :any;
  ItensTaRolando:any;
 

  constructor(private _homeService: HomeService, private _taRolandoService: TarolandoService) {


    // this.getItensHome();
    // this.getItensAtividades();
    
  //   $('.navbar-nav li a').click(() => {
  //     if ( !$(this).parent().hasClass('dropdown')) {
  //        $('.navbar-collapse').collapse('hide'); 
  //     }
  //  });
  

  }
  

  // getItensHome()
  // {
  //   this._homeService.GetTitulos().subscribe((data) => {
  //     this.Titulos = data;
  //   }, () => { }, () => {

  //     setTimeout(() => {
  //       $("#textoHome").owlCarousel({
  //         items: 1,
  //         autoPlay: true,
  //         loop: true,
  //         autoWidth: false,
  //         slideSpeed: 300,
  //         paginationSpeed: 800,
  //         autoplayTimeout: 1000,
  //         autoplayHoverPause: true,
  //         startPosition: 5
  //       });

  //     }, 200);

  //   });
  // }

  // getItensAtividades()
  // {
  //   this._taRolandoService.GetTarolando().subscribe((data) => {
  //     this.ItensTaRolando = data;    },()=>{},()=>{

  //       setTimeout(()=>{
  //         $("#clients").owlCarousel({
  //           items: 2,
  //           autoPlay: true,
  //           loop: true,
  //           slideSpeed: 300,
  //           paginationSpeed: 800,
  //           autoplayTimeout: 1000,
  //           autoplayHoverPause: true,
  //           startPosition: 5
  //         });         
  //       },200)       
  //     })
  // }

}
