import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private _http: HttpClient) { }


  public GetTitulos= (): Observable<any> => {
  
    return this._http.get("assets/json/jsonHome.json")
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }


  private handleError(error: Response) {
    return Observable.throw(error.json());
  }

}
