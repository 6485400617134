import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ficha-gaffair-poste',
  templateUrl: './ficha-gaffair-poste.component.html',
  styleUrls: ['./ficha-gaffair-poste.component.css']
})
export class FichaGaffairPosteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
