import { Component, OnInit } from '@angular/core';
import { TarolandoService } from '../tarolando.service';
import { HomeService } from '../home.service';

declare var $ :any;

@Component({
  selector: 'app-ficha-gaffair-hmi-k',
  templateUrl: './ficha-gaffair-hmi-k.component.html',
  styleUrls: ['./ficha-gaffair-hmi-k.component.css']
})
export class FichaGaffairHmiKComponent implements OnInit {
  
  Titulos :any; 
  ItensTaRolando :any;

  constructor(private _taRolandoService: TarolandoService) { }

  ngOnInit() {
     
    this.getItensAtividades();   
  }

  getItensAtividades()
  {
    this._taRolandoService.jsonTaRolandoGaffair().subscribe((data) => {
      this.ItensTaRolando = data;    },()=>{},()=>{

        setTimeout(()=>{
          $("#clients").owlCarousel({
            items: 2,
            autoPlay: true,
            loop: true,
            slideSpeed: 300,
            paginationSpeed: 800,
            autoplayTimeout: 100,
            autoplayHoverPause: true,
            startPosition: 5
          });         
        },200)       
      })
  }

}