import { Component, OnInit } from '@angular/core';
import { TarolandoService } from '../tarolando.service';
import { HomeService } from '../home.service';
import { Router } from '@angular/router';

declare var $ :any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  Titulos: any;
  ItensTaRolando: any;
  home = true;

  constructor(private _homeService: HomeService, private _taRolandoService: TarolandoService,private router: Router) { }

  ngOnInit(): void {
  
    // this.getItensHome();
    this.getItensAtividades();
    //this.carouselCards();

    this.home == false;
    
    $('.navbar-nav li a').click(() => {
      if ( !$(this).parent().hasClass('dropdown')) {
         $('.navbar-collapse').collapse('hide'); 
      }
   });

  }

    getItensAtividades()
  {
    this._taRolandoService.GetTarolando().subscribe((data) => {
      this.ItensTaRolando = data;    },()=>{},()=>{

        setTimeout(()=>{
          $("#clients").owlCarousel({
            items: 1,
            autoPlay: true,
            loop: true,
            slideSpeed: 300,
            paginationSpeed: 800,
            autoplayTimeout: 1000,
            autoplayHoverPause: true,
            startPosition: 5
          });         
        },200)       
      })
  }

  carouselCards()
  {
    $(".slider").owlCarousel({
      loop: false,
      autoplay: false,
      autoplayTimeout: 2000, //2000ms = 2s;
      autoplayHoverPause: false,
    });
  }
}