import { Component, OnInit } from '@angular/core';
import { EmailService } from '../email.service';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.css']
})
export class ContatoComponent implements OnInit {

  public name: any;
  public email: any;
  public message: any;

  response: any;


  constructor(public _emailService: EmailService) { }

  ngOnInit() {
  }

  sendEmail() {

    debugger
    let mail = { name: this.name, email: this.email, message: this.message };
    this._emailService.SendMail(mail).subscribe((x) => {

        this.response = 'Recebemos o seu email e retornaremos assim que possível';
        this.name = ''; 
        this.email = '';
        this.message = '';
        setTimeout(()=>{this.response = '';},9000);

    },()=>{

      this.response = 'Recebemos o seu email e retornaremos assim que possível';
      setTimeout(()=>{this.response = '';},9000);

    });
  }
}
