import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FilmagemComponent } from './filmagem/filmagem.component';
import { RouterModule, Routes } from '@angular/router';
import { SocialComponent } from './social/social.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContatoComponent } from './contato/contato.component';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GaffairComponent } from './gaffair/gaffair.component';
import { FichaTecnicaComponent } from './ficha-tecnica/ficha-tecnica.component';
import { FichaGaffairComponent } from './ficha-gaffair/ficha-gaffair.component';
import { FichaGaffairHmiKComponent } from './ficha-gaffair-hmi-k/ficha-gaffair-hmi-k.component';
import { FichaGaffairPirataComponent } from './ficha-gaffair-pirata/ficha-gaffair-pirata.component';
import { FichaGaffairFuracaoComponent } from './ficha-gaffair-furacao/ficha-gaffair-furacao.component';
import { FichaGaffairCoretorComponent } from './ficha-gaffair-coretor/ficha-gaffair-coretor.component';
import { FichaGaffairPosteComponent } from './ficha-gaffair-poste/ficha-gaffair-poste.component';
import { FichaKitCrystalComponent } from './ficha-kit-crystal/ficha-kit-crystal.component';
import { FichaLunixHalComponent } from './ficha-lunix-hal/ficha-lunix-hal.component';
import { FormsModule } from '@angular/forms';
import { FichaCrystalBolaComponent } from './ficha-crystal-bola/ficha-crystal-bola.component';
import { FichaSuperLunixComponent } from './ficha-super-lunix/ficha-super-lunix.component';
import { FichaLunixTrezentosSessentaComponent } from './ficha-lunix-trezentos-sessenta/ficha-lunix-trezentos-sessenta.component';
import { FichaTubeHalComponent } from './ficha-tube-hal/ficha-tube-hal.component';
import { FichaElipticoComponent } from './ficha-eliptico/ficha-eliptico.component';
import { FichaEsfera300Component } from './ficha-esfera300/ficha-esfera300.component';
import { FichaEsfera370Component } from './ficha-esfera370/ficha-esfera370.component';
import { SobreComponent } from './sobre/sobre.component';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


const appRoutes: Routes = [

  // {
  //   path:'',
  //   redirectTo:'home',
  //   pathMatch:'full'
  // },
  

  
];

@NgModule({
  declarations: [
    AppComponent,
    FilmagemComponent,
    SocialComponent,
    HomeComponent,
    AboutComponent,
    ContatoComponent,
    NavComponent,
    FooterComponent,
    GaffairComponent,
    FichaTecnicaComponent,
    FichaGaffairComponent,
    FichaGaffairHmiKComponent,
    FichaGaffairPirataComponent,
    FichaGaffairFuracaoComponent,
    FichaGaffairCoretorComponent,
    FichaGaffairPosteComponent,
    FichaKitCrystalComponent,
    FichaLunixHalComponent,
    FichaCrystalBolaComponent,
    FichaSuperLunixComponent,
    FichaLunixTrezentosSessentaComponent,
    FichaTubeHalComponent,
    FichaElipticoComponent,
    FichaEsfera300Component,
    FichaEsfera370Component,
    SobreComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(appRoutes)
    ],
    
  providers: [ { provide: LocationStrategy, useClass: HashLocationStrategy }],
  
  bootstrap: [AppComponent]


  
})
export class AppModule { }
