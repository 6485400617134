import { Component, OnInit } from '@angular/core';
import { TarolandoService } from '../tarolando.service';

declare var $:any;

@Component({
  selector: 'app-ficha-esfera370',
  templateUrl: './ficha-esfera370.component.html',
  styleUrls: ['./ficha-esfera370.component.css']
})
export class FichaEsfera370Component implements OnInit {

  ItensTaRolando:any;
  ItensTaRolandoDesktop:any;

  constructor(private _taRolandoService:TarolandoService) { }

  ngOnInit() {
    this.getItensAtividades();
    this.getItensAtividadesDesktop();
  }

  getItensAtividades()
  {
    this._taRolandoService.jsonTaRolandoSolarc370().subscribe((data) => {
      this.ItensTaRolando = data;    },()=>{},()=>{

        setTimeout(()=>{
          $("#clients").owlCarousel({
            items: 2,
            autoPlay: true,
            loop: true,
            slideSpeed: 300,
            paginationSpeed: 800,
            autoplayTimeout: 100,
            autoplayHoverPause: true,
            startPosition: 5
          });         
        },200)       
      })
  }

  getItensAtividadesDesktop()
  {
    this._taRolandoService.jsonTaRolandoSolarc370Desktop().subscribe((data) => {
      this.ItensTaRolandoDesktop = data;    },()=>{},()=>{

        setTimeout(()=>{
          $("#clientss").owlCarousel({
            items: 1,
            autoPlay: true,
            loop: true,
            slideSpeed: 300,
            paginationSpeed: 800,
            autoplayTimeout: 100,
            autoplayHoverPause: true,
            startPosition: 5
          });         
        },200)       
      })
  } 


}
