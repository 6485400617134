import { Component, OnInit } from '@angular/core';
import { TarolandoService } from '../tarolando.service';

declare var $:any;

@Component({
  selector: 'app-ficha-eliptico',
  templateUrl: './ficha-eliptico.component.html',
  styleUrls: ['./ficha-eliptico.component.css']
})
export class FichaElipticoComponent implements OnInit {

  ItensTaRolando:any;
  ItensTaRolandoDesktop:any;

  constructor(private _taRolandoService:TarolandoService) { }

  ngOnInit() {
    this.getItensAtividades();
    this.getItensAtividadesDesktop();
  }

  getItensAtividades()
  {
    this._taRolandoService.jsonTaRolandoHibridoEliptico().subscribe((data) => {
      this.ItensTaRolando = data;    },()=>{},()=>{

        setTimeout(()=>{
          $("#clients").owlCarousel({
            items: 2,
            autoPlay: true,
            loop: true,
            slideSpeed: 300,
            paginationSpeed: 800,
            autoplayTimeout: 100,
            autoplayHoverPause: true,
            startPosition: 5
          });         
        },200)       
      })
  }
  
  getItensAtividadesDesktop()
  {
    this._taRolandoService.jsonTaRolandoHibridoElipticoDesktop().subscribe((data) => {
      this.ItensTaRolandoDesktop = data;    },()=>{},()=>{

        setTimeout(()=>{
          $("#clientss").owlCarousel({
            items: 1,
            autoPlay: false,
            loop: false,
            slideSpeed: 300,
            paginationSpeed: 800,
            autoplayTimeout: 100,
            autoplayHoverPause: false,
            startPosition: 5
          });         
        },200)       
      })
  } 

}
