import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private _http: HttpClient) { }

  public SendMail = (data: any): Observable<any> => {
  debugger
  return this._http.post("https://emamil.azurewebsites.net/api/email?name=" + data.name + "&email=" + data.email + "&mensagem=" + data.message,"");


  }

  private handleError(error: Response) {
    return Observable.throw(error.json());
  }

}
 