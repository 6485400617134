import { Component, OnInit } from '@angular/core';
import { TarolandoService } from '../tarolando.service';

declare var $;

@Component({
  selector: 'app-ficha-tecnica',
  templateUrl: './ficha-tecnica.component.html',
  styleUrls: ['./ficha-tecnica.component.css']
})
export class FichaTecnicaComponent implements OnInit {


  Titulos;   
  ItensTaRolando;

  constructor(private _taRolandoService: TarolandoService) { }

  ngOnInit() {  
   
    this.getItensAtividades(); 
  }

  getItensAtividades()
  {
    this._taRolandoService.jsonTaRolandoGaffair().subscribe((data) => {
      this.ItensTaRolando = data;    },()=>{},()=>{

        setTimeout(()=>{
          $("#clients").owlCarousel({
            items: 2,
            autoPlay: true,
            loop: true,
            slideSpeed: 300,
            paginationSpeed: 800,
            autoplayTimeout: 100,
            autoplayHoverPause: true,
            startPosition: 5
          });         
        },200)       
      })
  }
}