import { Component, OnInit } from '@angular/core';
import { HomeService } from '../home.service';
import { TarolandoService } from '../tarolando.service';
import { Router } from '@angular/router';

declare var $;

@Component({
  selector: 'app-filmagem',
  templateUrl: './filmagem.component.html',
  styleUrls: ['./filmagem.component.css']
})
export class FilmagemComponent implements OnInit {

   Titulos;   
  ItensTaRolando;


  constructor(private _homeService: HomeService, private _taRolandoService: TarolandoService,private router: Router) { }

  ngOnInit(): void {
  
    this.getItensHome();
    this.getItensAtividades();

  }

  getItensHome()
  {
    this._homeService.GetTitulos().subscribe((data) => {
      this.Titulos = data;
    }, () => { }, () => {

      setTimeout(() => {
        $("#textoHome").owlCarousel({
          items: 1,
          autoPlay: true,
          loop: true,
          autoWidth: false,
          slideSpeed: 300,
          paginationSpeed: 800,
          autoplayTimeout: 1000,
          autoplayHoverPause: true,
          startPosition: 5
        });

      }, 200);

    });
  }

  getItensAtividades()
  {
    this._taRolandoService.GetTarolandoFilmagem().subscribe((data) => {
      this.ItensTaRolando = data;    },()=>{},()=>{

        setTimeout(()=>{
          $("#clients").owlCarousel({
            items: 2,
            autoPlay: true,
            loop: true,
            slideSpeed: 300,
            paginationSpeed: 800,
            autoplayTimeout: 1000,
            autoplayHoverPause: true,
            startPosition: 5
          });         
        },200)       
      })
  }

}
