import { Component, OnInit } from '@angular/core';
import { TarolandoService } from '../tarolando.service';

declare var $ :any;

@Component({
  selector: 'app-ficha-gaffair-pirata',
  templateUrl: './ficha-gaffair-pirata.component.html',
  styleUrls: ['./ficha-gaffair-pirata.component.css']
})
export class FichaGaffairPirataComponent implements OnInit {

  Titulos :any;   
  ItensTaRolando:any;
  ItensTaRolandoDesktop:any;

  constructor(private _taRolandoService: TarolandoService) { }

  ngOnInit() {
      
    this.getItensAtividadesDesktop();
    this.getItensAtividades();   
  }

  getItensAtividades()
  {
    
    this._taRolandoService.jsonTaRolandoGaffairPirata().subscribe((data) => {
      this.ItensTaRolando = data;    },()=>{},()=>{

        setTimeout(()=>{
          $("#clients").owlCarousel({
            items: 2,
            autoPlay: true,
            loop: true,
            slideSpeed: 300,
            paginationSpeed: 800,
            autoplayTimeout: 100,
            autoplayHoverPause: true,
            startPosition: 5
          });         
        },200)       
      })
  }



  getItensAtividadesDesktop()
  {
    this._taRolandoService.jsonTaRolandoGaffairPirataDesktop().subscribe((data) => {
      this.ItensTaRolandoDesktop = data;    },()=>{},()=>{

        setTimeout(()=>{
          $("#clientss").owlCarousel({
            items: 1,
            autoPlay: true,
            loop: true,
            slideSpeed: 300,
            paginationSpeed: 800,
            autoplayTimeout: 100,
            autoplayHoverPause: true,
            startPosition: 5
          });         
        },200)       
      })
  } 


  // getItensAtividades1()
  // {
    
  //   this._taRolandoService.jsonTaRolandoGaffairPirata1().subscribe((data) => {
  //     this.ItensTaRolando1 = data;    },()=>{},()=>{

  //       setTimeout(()=>{
  //         $("#clients").owlCarousel({
  //           items: 2,
  //           autoPlay: true,
  //           loop: true,
  //           slideSpeed: 300,
  //           paginationSpeed: 800,
  //           autoplayTimeout: 100,
  //           autoplayHoverPause: true,
  //           startPosition: 5
  //         });         
  //       },200)       
  //     })
  // }

}
