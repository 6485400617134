import { Component, OnInit } from '@angular/core';
import { TarolandoService } from '../tarolando.service';

declare var $ :any;

@Component({
  selector: 'app-ficha-lunix-hal',
  templateUrl: './ficha-lunix-hal.component.html',
  styleUrls: ['./ficha-lunix-hal.component.css']
})
export class FichaLunixHalComponent implements OnInit {

  Titulos :any;   
  ItensTaRolando:any;
  ItensTaRolandoDesktop:any;

  constructor(private _taRolandoService: TarolandoService) { }

  ngOnInit() {

    this.getItensAtividadesDesktop();
    this.getItensAtividades();   
  }

  getItensAtividades()
  {
    this._taRolandoService.jsonTaRolandoLinux().subscribe((data) => {
      this.ItensTaRolando = data;    },()=>{},()=>{

        setTimeout(()=>{
          $("#clients").owlCarousel({
            items: 2,
            autoPlay: true,
            loop: true,
            slideSpeed: 300,
            paginationSpeed: 800,
            autoplayTimeout: 100,
            autoplayHoverPause: true,
            startPosition: 5
          });         
        },200)       
      })
  }

  getItensAtividadesDesktop()
  {
    this._taRolandoService.jsonTaRolandoLinuxDesktop().subscribe((data) => {
      this.ItensTaRolandoDesktop = data;    },()=>{},()=>{

        setTimeout(()=>{
          $("#clientss").owlCarousel({
            items: 1,
            autoPlay: true,
            loop: true,
            slideSpeed: 300,
            paginationSpeed: 800,
            autoplayTimeout: 100,
            autoplayHoverPause: true,
            startPosition: 5
          });         
        },200)       
      })
  } 

  carouselCards()
  {
    $(".slider").owlCarousel({
      loop: false,
      autoplay: false,
      autoplayTimeout: 2000, //2000ms = 2s;
      autoplayHoverPause: false,
    });
  }
}
