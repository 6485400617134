import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TarolandoService {

  constructor(private _http: HttpClient) { }

  public GetTarolando = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolando.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  public GetTarolandoFilmagem = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoFilmagem.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  public jsonTaRolandoGaffair = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoGaffair.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  public jsonTaRolandoGaffairTeste = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoGaffairTeste.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  public jsonTaRolandoGaffairPirata = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoGaffairPirata.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  public jsonTaRolandoGaffairPirataDesktop = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoGaffairPirataDesktop.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  public jsonTaRolandoGaffairFuracao = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoGaffairFuracao.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  public jsonTaRolandoGaffairFuracaoDesktop = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoGaffairFuracaoDesktop.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  
  public jsonTaRolandoLinux = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoLinux.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  public jsonTaRolandoLinuxDesktop = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoLinuxDesktop.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }
  
  public jsonTaRolandoCrystalBola = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoCrystalBola.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  public jsonTaRolandoCrystalBolaDesktop = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoCrystalBolaDesktop.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  public jsonTaRolandoHibridoEliptico = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoHibridoEliptico.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  public jsonTaRolandoHibridoElipticoDesktop = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoHibridoElipticoDesktop.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  public jsonTaRolandoSolarc370 = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoSolarc370.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  public jsonTaRolandoSolarc370Desktop = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoSolarc370Desktop.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  public jsonTaRolandoSolarc300 = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoSolarc300.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  public jsonTaRolandoSolarc300Desktop = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoSolarc300Desktop.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  public jsonTaRolandoTube = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoTube.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  public jsonTaRolandoTubeDesktop = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoTubeDesktop.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  public jsonTaRolandoLinux360 = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoLinux360.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  
  public jsonTaRolandoSuperLinux = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoSuperLinux.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  public jsonTaRolandoSuperLinuxDesktop = (): Observable<any> => {
  
    return this._http.get("assets/json/jsonTaRolandoSuperLinuxDesktop.json");
      // .map((response: Response) => response.json())
      // .catch(this.handleError);

  }

  private handleError(error: Response) {
    return Observable.throw(error.json());
  }

}
