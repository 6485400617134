import { Component, OnInit } from '@angular/core';
import { TarolandoService } from '../tarolando.service';

declare var $:any;

@Component({
  selector: 'app-ficha-gaffair-furacao',
  templateUrl: './ficha-gaffair-furacao.component.html',
  styleUrls: ['./ficha-gaffair-furacao.component.css']
})
export class FichaGaffairFuracaoComponent implements OnInit {

  constructor(private _taRolandoService: TarolandoService) { }

  Titulos :any;   
  ItensTaRolando:any;
  ItensTaRolandoDesktop:any;

  ngOnInit() {
      
    this.getItensAtividades();   
    this.getItensAtividadesDesktop();
  }

  getItensAtividades()
  {
    this._taRolandoService.jsonTaRolandoGaffairFuracao().subscribe((data) => {
      this.ItensTaRolando = data;    },()=>{},()=>{

        setTimeout(()=>{
          $("#clients").owlCarousel({
            items: 1,
            autoPlay: true,
            loop: true, 
            slideSpeed: 300,
            paginationSpeed: 800,
            autoplayTimeout: 100,
            autoplayHoverPause: true,
            startPosition: 5
          });         
        },200)       
      })
  }

  getItensAtividadesDesktop()
  {
    this._taRolandoService.jsonTaRolandoGaffairFuracaoDesktop().subscribe((data) => {
      this.ItensTaRolandoDesktop = data;    },()=>{},()=>{

        setTimeout(()=>{
          $("#clientss").owlCarousel({
            items: 1,
            autoPlay: true,
            loop: true,
            slideSpeed: 300,
            paginationSpeed: 800,
            autoplayTimeout: 100,
            autoplayHoverPause: true,
            startPosition: 5
          });         
        },200)       
      })
  } 


}
