import { Component, OnInit } from '@angular/core';
import { TarolandoService } from '../tarolando.service';

declare var $:any;

@Component({
  selector: 'app-ficha-crystal-bola',
  templateUrl: './ficha-crystal-bola.component.html',
  styleUrls: ['./ficha-crystal-bola.component.css']
})
export class FichaCrystalBolaComponent implements OnInit {

  Titulos :any;   
  ItensTaRolando:any;
  ItensTaRolandoDesktop:any;


  constructor(private _taRolandoService: TarolandoService) { }

  ngOnInit() {
  
    this.getItensAtividades();
    this.getItensAtividadesDesktop();
  }

  getItensAtividades()
  {
    this._taRolandoService.jsonTaRolandoCrystalBola().subscribe((data) => {
      this.ItensTaRolando = data;    },()=>{},()=>{

        setTimeout(()=>{
          $("#clients").owlCarousel({
            items: 1,
            autoPlay: true,
            loop: true,
            slideSpeed: 300,
            paginationSpeed: 800,
            autoplayTimeout: 100,
            autoplayHoverPause: true,
            startPosition: 5
          });         
        },200)       
      })
  }

  getItensAtividadesDesktop()
  {
    this._taRolandoService.jsonTaRolandoCrystalBolaDesktop().subscribe((data) => {
      this.ItensTaRolandoDesktop = data;    },()=>{},()=>{

        setTimeout(()=>{
          $("#clientss").owlCarousel({
            items: 1,
            autoPlay: true,
            loop: true,
            slideSpeed: 300,
            paginationSpeed: 800,
            autoplayTimeout: 100,
            autoplayHoverPause: true,
            startPosition: 5
          });         
        },200)       
      })
  } 


}
