import { group } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, RouterLink } from '@angular/router';
import { ICategoryStructure } from '../../myApp.model';

declare var $ :any;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  route: any;
  router: any;

  constructor() { }

  ngOnInit() {

    $('.navbar-nav li a').click(() => {
      if ( !$(this).parent().hasClass('dropdown')) {
         $('.navbar-collapse').collapse('hide'); 
      }
   });

   $('.navbar-nav li a').click(() => {
    if ( !$(this).parent().hasClass('dropdown')) {
       $('.toggle-collapse').collapse('hide'); 
    }
 });
  
  //  $(document).ready(() =>{
  //   $('.dropdown-submenu a.test').on("click", function(e:any){
  //     $(this).next('ul').toggle();
  //     e.preventDefault();
  //   });
  // });

   $("#menu-head-menu li").click(function(){
     $(this).find("ul").slideToggle().stopPropagation();
    
 });

 $("#jq-dropdown-1").on("click",function(event){
  event.stopPropagation();
  $('#jq-dropdown-1').dropdown('show');
});



}


onClick(){
  let x = document.querySelector("tf-contact");
  if (x){
      x.scrollIntoView();
  }
}



  }
  
 
 
 




//   $("#menu-head-menu li").click(function(){
//     $(this).find("ul").slideToggle().stopPropagation();
    
// });



//    $(document).ready(() =>{
//     $('.dropdown-submenu a.test').on("click", function(e:any){
//       $(this).next('ul').toggle();
//       e.stopPropagation();
//       e.preventDefault();
//     });
//   });
// }
  




