import { Component, OnInit } from '@angular/core';
import { TarolandoService } from '../tarolando.service';

declare var $:any;

@Component({
  selector: 'app-gaffair',
  templateUrl: './gaffair.component.html',
  styleUrls: ['./gaffair.component.css']
})

export class GaffairComponent implements OnInit {

  Titulos :any;   
  ItensTaRolando:any;
  constructor(private _taRolandoService: TarolandoService) { }

  ngOnInit() {

    this.getItensAtividades();
  }

  getItensAtividades()
  {
    this._taRolandoService.jsonTaRolandoGaffair().subscribe((data) => {
      this.ItensTaRolando = data;    },()=>{},()=>{

        setTimeout(()=>{
          $("#clients").owlCarousel({
            items: 2,
            autoPlay: false,
            loop: true,
            slideSpeed: 300,
            paginationSpeed: 800,
            autoplayTimeout: 1000,
            autoplayHoverPause: true,
            startPosition: 5
          });         
        },200)       
      })
  }

}
