import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ficha-kit-crystal',
  templateUrl: './ficha-kit-crystal.component.html',
  styleUrls: ['./ficha-kit-crystal.component.css']
})
export class FichaKitCrystalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
