import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FilmagemComponent } from './filmagem/filmagem.component';
import { HomeComponent } from './home/home.component';
import { GaffairComponent } from './gaffair/gaffair.component';
import { FichaTecnicaComponent } from './ficha-tecnica/ficha-tecnica.component';
import { FichaGaffairComponent } from './ficha-gaffair/ficha-gaffair.component';
import { FichaGaffairHmiKComponent } from './ficha-gaffair-hmi-k/ficha-gaffair-hmi-k.component';
import { FichaGaffairPirataComponent } from './ficha-gaffair-pirata/ficha-gaffair-pirata.component';
import { FichaGaffairFuracaoComponent } from './ficha-gaffair-furacao/ficha-gaffair-furacao.component';
import { FichaKitCrystalComponent } from './ficha-kit-crystal/ficha-kit-crystal.component';
import { FichaLunixHalComponent } from './ficha-lunix-hal/ficha-lunix-hal.component';
import { ContatoComponent } from './contato/contato.component';
import { FichaCrystalBolaComponent } from './ficha-crystal-bola/ficha-crystal-bola.component';
import { FichaElipticoComponent } from './ficha-eliptico/ficha-eliptico.component';
import { FichaEsfera300Component } from './ficha-esfera300/ficha-esfera300.component';
import { FichaEsfera370Component } from './ficha-esfera370/ficha-esfera370.component';
import { FichaLunixTrezentosSessentaComponent } from './ficha-lunix-trezentos-sessenta/ficha-lunix-trezentos-sessenta.component';
import { FichaSuperLunixComponent } from './ficha-super-lunix/ficha-super-lunix.component';
import { FichaTubeHalComponent } from './ficha-tube-hal/ficha-tube-hal.component';
import { SobreComponent } from './sobre/sobre.component';

const routes: Routes = [
  {
    path:'',
    redirectTo:'home',
    pathMatch:'full'
  },

  {
    path:'home',
    component:HomeComponent
  },
  
  {   
    path:'filmagem',
    component:FilmagemComponent
  },
  
  {   
    path:'gaffair',
    component:GaffairComponent
  },

  {   
    path:'fichatecnica',
    component:FichaTecnicaComponent
  },

  {   
    path:'fichagaffairPirata',
    component:FichaGaffairPirataComponent
  },
  {   
    path:'fichagaffairFuracao',
    component:FichaGaffairFuracaoComponent
  },
  {   
    path:'fichagaffair',
    component:FichaGaffairComponent
  },
  {   
    path:'fichagaffairhmi',
    component:FichaGaffairHmiKComponent
  },
  {
    path:'fichakitcrystal',
    component:FichaKitCrystalComponent
  },
  {
    path:'fichacrystalbola',
    component:FichaCrystalBolaComponent
  },
  {
    path:'lunixhal',
    component:FichaLunixHalComponent
  },
  {
    path:'fichaeliptico',
    component:FichaElipticoComponent
  },
  {
    path:'fichaesfera300',
    component:FichaEsfera300Component
  },
  {
    path:'fichaesfera370',
    component:FichaEsfera370Component
  },
  {
    path:'fichalunix360',
    component:FichaLunixTrezentosSessentaComponent
  },
  {
    path:'fichasuperlunix',
    component:FichaSuperLunixComponent
  },
  {
    path:'fichatubehal',
    component:FichaTubeHalComponent
  },
  {
    path:'contato',
    component:ContatoComponent
  },
  {
    path:'sobre',
    component:SobreComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
